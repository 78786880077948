import React from "react";

const KeyFeatures = ({ content }) => {
  return (
    <div className="d-flex flex-column align-items-center py-4">
      <p className="fs-2 fw-semibold text-dark mt-3">Key Features</p>
      <div className="row px-5 w-100 justify-content-center">
        {content.map((item) => {
          return (
            <div className="col-lg-3 col-md-4 p-3 px-5 text-center mb-3 col-sm-6 rounded features-card">
              <img className="mb-3" src={item.cardSVG} alt="" />
              <p className="fs-5 text-dark">{item.cardHeader}</p>
              <p>{item.cardDescription}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KeyFeatures;
