import React from "react";
import ScrollToTopOnRouteChange from "../../components/ScrollToTopOnRouteChange";
import Header from "../../components/Header";
import Agency from "./components/Agency";
import HowItWorks from "./components/HowItWorks";
import Features from "./components/Features";
import Counter from "./components/Counter";
import Testimonials from "./components/Testimonials";
import Faqs from "./components/Faqs";
import Availableon from "./components/Availableon";
import Contact from "./components/Contact";

const Home = () => {
  return (
    <>
      <ScrollToTopOnRouteChange />
      <Header />
      <Agency />
      <HowItWorks />
      <Features />
      <Counter />
      <Testimonials />
      <Faqs />
      <Availableon />
      <Contact />
    </>
  );
};

export default Home;
