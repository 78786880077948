import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import login7 from "../../../assets/images/login 7.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const vendorManagementContent = {
  BannerContent: {
    bannerHeader: "Vendor Management",
    bannerDescription: `SocioHood's Vendor Management system is an integral part of our comprehensive Township Management Solution. It streamlines vendor interactions, ensures efficient complaint resolution, and enhances overall community satisfaction. With a dedicated Vendor Portal and robust performance monitoring capabilities, it empowers administrators and vendors to collaborate seamlessly.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Vendor Portal",
      cardDescription: `The Vendor Portal provides vendors with direct access to the system, allowing them to view
        assigned tasks, respond to complaints, and update job statuses in real-time. This transparency
        fosters clear communication between vendors and administrators.`,
    },
    {
      cardSVG: location,
      cardHeader: "Task Assignment:",
      cardDescription: `Administrators can efficiently assign tasks to vendors, specifying details, deadlines, and priorities.
        Vendors receive task notifications instantly, ensuring swift action.`,
    },
    {
      cardSVG: tracking,
      cardHeader: "Performance Monitoring:",
      cardDescription: `SocioHood's system tracks vendor performance metrics, including response times, issue
        resolution rates, and resident feedback. This data is crucial for assessing vendor effectiveness and
        making informed decisions`,
    },
    {
      cardSVG: history,
      cardHeader: "Invoice Generation:",
      cardDescription: `Vendors can generate invoices directly through the system, streamlining the billing process and
        reducing paperwork.`,
    },
    {
      cardSVG: feedback,
      cardHeader: "Rating and Feedback:",
      cardDescription: `After a service request is completed, residents can rate the vendor's performance and provide
        feedback. This valuable input helps administrators make vendor selections based on past performance.`,
    },
    {
      cardSVG: analytics,
      cardHeader: "Vendor Profiles:",
      cardDescription: `Each vendor has a dedicated profile within the system, containing essential details, service
        history, and performance metrics. This ensures accountability and transparency`,
    },
    {
      cardSVG: login7,
      cardHeader: "Communication Hub:",
      cardDescription: `The system serves as a central hub for vendor communication, facilitating discussions, clarifications,
        and updates between vendors and administrators.`,
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Efficient Task Management",
        cardDescription:
          "The Vendor Portal simplifies task assignment and progress tracking, optimizing vendor efficiency.",
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Transparent Performance",
        cardDescription:
          "Performance monitoring ensures accountability and motivates vendors to provide top-notch services",
      },
      {
        cardSVG: energy,
        cardHeader: "Streamlined Billing:",
        cardDescription:
          "Automated invoice generation reduces administrative burdens and expedites payment processing.",
      },
      {
        cardSVG: review,
        cardHeader: "Resident Satisfaction",
        cardDescription:
          "Rating and feedback mechanisms empower residents to voice their opinions, leading to improved service quality.",
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Data-Driven Decisions",
        cardDescription:
          "Access to vendor performance data allows administrators to make informed vendor selections and optimizations.",
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Leading Research Statistics:",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 65,
        cardDescription: `A study by the National Emergency Number Association (NENA) found that
          location-based alerts reduce emergency response times by up to 65%.`,
      },
      {
        cardProgress: 30,
        cardDescription: `According to FEMA (Federal Emergency Management Agency),
          communities with effective alert management systems report a 30% increase
          in resident safety awareness.`,
      },
      {
        cardProgress: 50,
        cardDescription: `The American Red Cross notes that timely SOS alerts can reduce the
          severity of medical emergencies by up to 50%.`,
      },
      {
        cardProgress: 80,
        cardDescription: `A survey by Rave Mobile Safety shows that 80% of users find
          customizable alert notifications more useful during emergencies.`,
      },
      {
        cardProgress: 40,
        cardDescription: `The Department of Homeland Security (DHS) confirms that two-way
          communication in alert systems improves emergency response accuracy
          by 40%.`,
      },
    ],
  },
};
