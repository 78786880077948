import React from "react";
import Benefits from "./Benefits";
import DetailsBanner from "./DetailsBanner";
import KeyFeatures from "./KeyFeatures";
import Statistics from "./Statistics";
import TakePreview from "./TakePreview";

const DetailsBody = ({ content }) => {
  return (
    <>
      <DetailsBanner content={content?.BannerContent} />
      <KeyFeatures content={content?.keyFeaturesContent} />
      <TakePreview content={content?.takePreviewContent} />
      <Benefits content={content?.benefitsContent} />
      <Statistics content={content?.statisticsContent} />
    </>
  );
};

export default DetailsBody;
