import React, { useState } from "react";
import { Link } from "react-router-dom";
import ApiCall from "../../../api/ApiCall";
import { customerRoutes } from "../../../api/Routes";

const Contact = () => {
  const [name, setname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const emailValidation =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleReset = () => {
    setname("");
    setPhoneNumber("");
    setEmail("");
    setMessage("");
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    const data = {
      userName: name,
      userNumber: phoneNumber,
      userEmail: email,
      message: message,
    };
    const res = await ApiCall("post", customerRoutes.addContact, data);
    if (res?.success) {
      handleReset();
      alert("Form Submitted Successfully");
    }
  };

  return (
    <section className="section bg-light" id="contact">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-8 col-lg-6 text-center">
            <h6 className="subtitle">
              Our <span className="fw-bold">Contact Us</span>
            </h6>
            <h2 className="title">Get in Touch</h2>
            <p className="text-muted">We're Here to Help!</p>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="d-flex align-items-center mb-5">
              <div className="flex-shrink-0">
                <img
                  src="images/hello-icon.png"
                  alt="..."
                  height="80"
                  className=""
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h2 className="mb-0">Say Hello!</h2>
              </div>
            </div>

            <div className="mb-4">
              <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0">
                  <div className="contact-icon bg-soft-primary text-primary">
                    <i className="mdi mdi-email"></i>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="mb-0 fs-18">Email</h5>
                </div>
              </div>
              <p className="mb-1">
                <i className="mdi mdi-arrow-right-thin text-muted me-1"></i>
                <Link
                  to={"mailto:contact@sociohood.com"}
                  className="text-secondary"
                >
                  contact@sociohood.com
                </Link>
              </p>
            </div>
            <div className="mb-4">
              <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0">
                  <div className="contact-icon bg-soft-primary text-primary">
                    <i className="mdi mdi-phone"></i>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="mb-0 fs-18">Phone</h5>
                </div>
              </div>
              <p className="mb-1">
                <i className="mdi mdi-arrow-right-thin text-muted me-1"></i>
                <Link to={null} className="text-secondary">
                  (+91) 84098 97416
                </Link>
              </p>
              <p className="">
                <i className="mdi mdi-arrow-right-thin text-muted me-1"></i>
                <Link to={null} className="text-secondary">
                  (+91) 79802 44189
                </Link>
              </p>
            </div>
            <div className="">
              <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0">
                  <div className="contact-icon bg-soft-primary text-primary">
                    <i className="mdi mdi-google-maps"></i>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="mb-0 fs-18">Address</h5>
                </div>
              </div>
              <h5 className="fs-16 mb-2 text-secondary">
                <i className="mdi mdi-arrow-right-thin text-muted me-1"></i>
                Bihar
              </h5>
              <p className="text-muted lh-base">
                M6 Vijay Aman Apartment, Main Road, Kankarbagh, Patna 800020,
                Bihar.
              </p>
              <h5 className="fs-16 mb-2 text-secondary">
                <i className="mdi mdi-arrow-right-thin text-muted me-1"></i>{" "}
                Kolkata
              </h5>
              <p className="text-muted lh-base mb-0">
                107/1 Lake Gardens, Kolkata 700045, West Bengal.
              </p>
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1">
            <div className="card contact-form rounded-lg mt-4 mt-lg-0">
              <div className="card-body p-5">
                <form onSubmit={handleAddContact}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="formFirstName" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formFirstName"
                          placeholder="Your full name..."
                          required
                          value={name}
                          onChange={(event) => setname(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="formEmail" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="formEmail"
                          placeholder="Your email..."
                          required
                          name="email"
                          pattern={emailValidation}
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="formPhone" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formPhone"
                          placeholder="Type phone number..."
                          required
                          name="phone"
                          inputMode="numeric"
                          value={phoneNumber}
                          maxLength="10"
                          minLength="10"
                          onChange={(e) => {
                            if (/^[0-9]*$/.test(e.target.value)) {
                              setPhoneNumber(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-4">
                        <label htmlFor="formMessages" className="form-label">
                          Messages
                        </label>
                        <textarea
                          className="form-control"
                          id="formMessages"
                          rows="4"
                          placeholder="Type messages..."
                          required
                          name="contact_message"
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-gradient-primary">
                    Send Messages <i className="mdi mdi-send ms-1"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
