import React from "react";
import { Link } from "react-router-dom";

const Availableon = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="text-center">
              <h1 className="display-5 fw-bold mb-4">
                Available For Your <br />
                Smartphone.
              </h1>
              <p className="text-muted mx-auto mb-5 w-75">
                Your Community at Your Fingertips - Seamless Society Management
                on Your Smartphone!
              </p>
              <Link
                target="_blank"
                to={"https://apps.apple.com/us/app/Sociohood/id1644508023"}
                className="me-1"
              >
                <img
                  src="images/i-store.png"
                  alt=""
                  className="shadow"
                  height="52"
                />
              </Link>
              <Link
                target="_blank"
                to={
                  "https://play.google.com/store/apps/details?id=com.sociohood"
                }
              >
                <img
                  src="images/play-store.png"
                  alt=""
                  className="shadow"
                  height="52"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Availableon;
