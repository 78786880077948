import React from "react";

const Counter = () => {
  return (
    <section className="section cta-bg">
      <div className="bg-overlay bg-dark"></div>
      <div className="container">
        <div className="row" id="counter">
          <div className="col-sm-6 col-lg-3">
            <div className="text-center my-3">
              <div className="d-flex align-items-center counter-content text-start">
                <div className="flex-shrink-0">
                  <i className="mdi mdi-clock-outline text-white display-5"></i>
                </div>
                <div className="flex-grow-1 ms-4">
                  <h1 className="text-white">
                    <span className="counter_value" data-target="825">
                      70
                    </span>
                    %
                  </h1>
                  <p className="counter-name text-white opacity-75 mb-0 text-uppercase">
                    Cut in operational time
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="text-center my-3">
              <div className="d-flex align-items-center counter-content text-start">
                <div className="flex-shrink-0">
                  <i className="mdi mdi-home-account text-white display-5"></i>
                </div>
                <div className="flex-grow-1 ms-4">
                  <h1 className="text-white">
                    <span className="counter_value" data-target="1800">
                      8500
                    </span>
                    +
                  </h1>
                  <p className="counter-name text-white opacity-75 mb-0 text-uppercase">
                    Unique Address/Property onboarded
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="text-center my-3">
              <div className="d-flex align-items-center counter-content text-start">
                <div className="flex-shrink-0">
                  <i className="mdi mdi-currency-usd-off text-white display-5"></i>
                </div>
                <div className="flex-grow-1 ms-4">
                  <h1 className="text-white">
                    <span className="counter_value" data-target="599">
                      60
                    </span>
                    %
                  </h1>
                  <p className="counter-name text-white opacity-75 mb-0 text-uppercase">
                    cost reduction with Sociohood integration
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="text-center my-3">
              <div className="d-flex align-items-center counter-content text-start">
                <div className="flex-shrink-0">
                  <i className="mdi mdi-account-clock text-white display-5"></i>
                </div>
                <div className="flex-grow-1 ms-4">
                  <h1 className="text-white">
                    <span className="counter_value" data-target="2000">
                      85
                    </span>
                    %
                  </h1>
                  <p className="counter-name text-white opacity-75 mb-0 text-uppercase">
                    Increase in vendor performance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counter;
