import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import escalation from "../../../assets/images/escalation 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const assetManagementContent = {
  BannerContent: {
    bannerHeader: "Asset Booking and Management",
    bannerDescription: `SocioHood's Task Management System is a
    comprehensive solution that simplifies task
    assignment, tracking, and completion within
    residential communities, organizations, and
    townships. This feature streamlines workflows,
    fosters accountability, and ensures that tasks are
    efficiently managed.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Resource Reservation:",
      cardDescription: `Our system provides an intuitive platform for users to reserve assets such as meeting rooms,
      sports facilities, and equipment. Booking requests are efficiently managed, eliminating
      conflicts and double bookings`,
    },
    {
      cardSVG: location,
      cardHeader: "Availability Calendar:",
      cardDescription: `Users can easily check the availability of assets through a centralized calendar, making it
      convenient to plan and schedule activities.`,
    },
    {
      cardSVG: tracking,
      cardHeader: "Asset Catalog:",
      cardDescription: `Maintain a comprehensive catalog of assets, complete with descriptions, images, and
      specifications. This feature simplifies the selection and booking process.`,
    },
    {
      cardSVG: escalation,
      cardHeader: "Booking Rules and Permissions:",
      cardDescription: `Administrators can define booking rules and permissions based on user roles, ensuring that assets
      are reserved according to the organization's policies.`,
    },
    {
      cardSVG: history,
      cardHeader: "Reservation History:",
      cardDescription: `Keep a record of all asset reservations and usage history for accountability and reporting
      purposes.`,
    },
    {
      cardSVG: feedback,
      cardHeader: "Automated Notifications:",
      cardDescription: `Users receive automated notifications and reminders for upcoming bookings, reducing the
      chances of missed reservations.`,
    },
    {
      cardSVG: analytics,
      cardHeader: "Asset Maintenance:",
      cardDescription: `Schedule and track asset maintenance to ensure that resources are always in optimal
      condition.`,
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Efficiency",
        cardDescription: `Streamlined task assignment and tracking save time and ensure tasks are
        completed on schedule.`,
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Accountability",
        cardDescription: `Assigning tasks and tracking progress fosters accountability among team members
        and residents.`,
      },
      {
        cardSVG: energy,
        cardHeader: "Transparency",
        cardDescription: `Real-time updates and notifications enhance transparency, ensuring everyone
        is on the same page.`,
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Productivity",
        cardDescription: `Prioritizing tasks based on urgency and importance improves productivity and
        time management.`,
      },
      {
        cardSVG: review,
        cardHeader: "Data-Driven Insights",
        cardDescription: `Task-related reports and analytics provide insights for better resource allocation
        and process improvement.`,
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Leading Research Statistics",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 20,
        cardDescription: `A survey by McKinsey reveals that organizations implementing efficient
        resource management systems can reduce operational costs by up to 20%.`,
      },
      {
        cardProgress: 15,
        cardDescription: `According to Gartner, optimized resource booking and utilization can result
        in a 15% increase in overall productivity.`,
      },
      {
        cardProgress: 25,
        cardDescription: `Aberdeen Group reports a 25% reduction in resource-related disputes
        in organizations using automated booking systems.`,
      },
      {
        cardProgress: 10,
        cardDescription: `Deloitte highlights that streamlined asset management can lead to a 10%
        improvement in customer satisfaction for service-based industries.`,
      },
      {
        cardProgress: 12,
        cardDescription: `Harvard Business Review finds that organizations with effective resource
        management systems see a 12% increase in employee engagement.
        `,
      },
    ],
  },
};
