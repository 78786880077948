import React, { useEffect, useRef } from "react";

const CircularProgressBar = ({ progress }) => {
  const radius = 55 / 2;
  const circumference = 2 * Math.PI * radius;
  const dasharray = circumference;
  const dashoffset = circumference - (progress / 100) * circumference;

  const circleRef = useRef(null);

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.transition = "stroke-dashoffset 0.5s ease-in-out";
      circleRef.current.style.strokeDashoffset = dashoffset;
    }
  }, [dashoffset]);

  return (
    <div className="progress-container rounded-circle">
      <svg
        className="progress-ring"
        width="100%"
        height="100%"
        viewBox="0 0 55 55"
      >
        <circle
          className="progress-circle"
          r={radius}
          cx={55 / 2}
          cy={55 / 2}
          fill="transparent"
          strokeWidth="8"
          stroke="#0074d9" // Set the stroke color here
          strokeDasharray={dasharray}
          strokeDashoffset={dasharray} // Initialize it to dasharray to hide the progress initially
          ref={circleRef}
        />
      </svg>

      <div className="progress-value text-dark fw-semibold">{progress}%</div>
    </div>
  );
};

export default CircularProgressBar;
