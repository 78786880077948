const images = {
  HOME_1_TOP: require("../assets/images/home_1_top.png"),
  LOGO_MAIN: require("../assets/images/logo_main.png"),
  COMPLAINT_MANAGEMENT: require("../assets/images/complaint_management.png"),
  VENDOR_MANAGEMENT: require("../assets/images/vendor_management.png"),
  VISITOR_MANAGEMENT: require("../assets/images/visitor_management.png"),
  COMMUNITY_MANAGEMENT: require("../assets/images/community_management.png"),
};

const icons = {

};

export { images, icons };
