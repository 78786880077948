import React from "react";

const Benefits = ({ content }) => {
  return (
    <div className="container">
      <div className="py-5 row w-100 benefits-wrapper">
        <div className="col-lg-6 col-md-12">
          <span className="d-flex align-items-center justify-content-center px-3">
            <img src={content.benefitsMainImg} alt="" />
          </span>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="d-flex flex-column">
            <p className="fs-2 fw-semibold text-dark">
              {content.benefitsHeader}
            </p>
            <div className="d-flex flex-column">
              {content.benefitsCards.map((item) => {
                return (
                  <div className="d-flex gap-4">
                    <div className="p-1" style={{ width: "5%" }}>
                      <span
                        className="shadow rounded-circle p-1 d-flex align-items-center justify-content-center"
                        style={{ height: "34px", width: "34px" }}
                      >
                        <img src={item.cardSVG} alt="" />
                      </span>
                    </div>
                    <div style={{ width: "95%" }}>
                      <p className="fs-4 text-dark">{item.cardHeader}</p>
                      <p>{item.cardDescription}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
