import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../../utils/images";

const Agency = () => {
  return (
    <section className="hero-1" id="home">
      <div className="bg-overlay-img"></div>
      <div className="container">
        <div className="row align-items-center hero-content">
          <div className="col-lg-5">
            <h1 className="hero-title fw-bold mb-4 pb-3 text-white">
              Township and Organization Management System.
            </h1>
            <p className="text-white opacity-75 mb-4 pb-3 fs-15">
              At SocioHood, we are driven by a relentless passion for innovation
              and a commitment to redefine the way communities, organizations,
              and townships are managed. Our journey began with a vision – a
              vision to empower our clients with a holistic Township Management
              System that simplifies complexity, enhances efficiency, and
              fosters transparency
            </p>
            <Link
              target="_blank"
              to={"https://play.google.com/store/apps/details?id=com.sociohood"}
              className="btn btn-white form-btn"
            >
              <span>Download the App</span>{" "}
              <i className="mdi mdi-download download-icon"></i>
            </Link>
            {/* <div className="subscribe-form me-lg-5 mb-5 mb-lg-0">
              <i className="mdi mdi-email-outline form-icon"></i>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Email Address"
              />
            </div> */}
          </div>
          <div className="col-lg-7 text-center text-lg-end">
            <img src={images.HOME_1_TOP} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="hero-bottom-shape">
              <img
                src="images/heros/hero-1-bottom-shape.png"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="down-arrow-btn">
              <Link to="#how-it-work" className="down-arrow">
                <i className="mdi mdi-arrow-down"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Agency;
