import React from "react";

const Faqs = () => {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-8 col-lg-6 text-center">
            <h6 className="subtitle">
              Our <span className="fw-bold">FAQs</span>
            </h6>
            <h2 className="title">Frequently Asked Questions</h2>
            <p className="text-muted">
              Answers to Your Questions - Everything You Need to Know About Our
              Sociohood
            </p>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-4">
            <img
              src="images/faq.png"
              alt=""
              className="img-fluid d-block mx-auto"
            />
          </div>

          <div className="col-lg-7 offset-lg-1">
            <div
              className="accordion accordion-flush faqs-accordion mt-4 mt-lg-0"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >
                    What is SocioHood's Township Management System?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-secondary">
                    SocioHood's Township Management System is a comprehensive
                    solution designed to streamline and automate various aspects
                    of residential communities, organizations, and townships. It
                    offers modules for Complaint Management, Visitor Management,
                    Vendor Management, Employee Management, and more.
                  </div>
                </div>
              </div>
              {/* <!-- accordion-header end --> */}

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    How does SocioHood's Visitor Management System work?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-secondary">
                    Our Visitor Management System simplifies visitor
                    registration, badge issuance, tracking, and notification to
                    enhance security and convenience within your community or
                    organization.
                  </div>
                </div>
              </div>
              {/* <!-- accordion-header end --> */}

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How can I request a demo of SocioHood's Township Management
                    System?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-secondary">
                    To request a demo, simply visit our website and fill out the
                    demo request form provided. Our team will promptly get in
                    touch with you to schedule a demonstration of our system's
                    capabilities.
                  </div>
                </div>
              </div>
              {/* <!-- accordion-header end --> */}

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    What modules are included in SocioHood's Township Management
                    System?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-secondary">
                    Our Township Management System comprises various modules
                    such as Complaint Management, Visitor Management, Vendor
                    Management, Employee Management, Task Management, Security
                    and Alert Management, and more.
                  </div>
                </div>
              </div>
              {/* <!-- accordion-header end --> */}

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    How does SocioHood's Vendor Management System benefit
                    communities and organizations?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-secondary">
                    Our Vendor Management System offers a dedicated portal for
                    efficient vendor collaboration, performance monitoring, and
                    streamlined procurement processes.
                  </div>
                </div>
              </div>
              {/* <!-- accordion-header end --> */}

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    Is SocioHood's Township Management System customizable to
                    suit our specific community or organization's needs?
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-secondary">
                    Yes, SocioHood's system is highly customizable to
                    accommodate the unique requirements and preferences of your
                    community or organization. Our team will work closely with
                    you to tailor the solution accordingly.
                  </div>
                </div>
              </div>
              {/* <!-- accordion-header end --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
