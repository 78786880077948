import React from "react";
import { Link } from "react-router-dom";

const BackToTop = () => {
  return (
    <Link className="back-to-top-btn btn btn-gradient-primary" id="back-to-top">
      <i className="mdi mdi-chevron-up"></i>
    </Link>
  );
};

export default BackToTop;
