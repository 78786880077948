import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { images } from "../utils/images";

const HeaderNew = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: scrolling ? "#2d6bec" : "transparent",
    transition: "background-color 0.3s",
  };

  return (
    <nav
      className="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky-dark"
      id="navbar-sticky"
      style={navbarStyle}
    >
      <div className="container">
        {/* <!-- LOGO --> */}
        <a className="logo text-uppercase" href="#home">
          <img
            src={images.LOGO_MAIN}
            style={{ height: 50, width: 90 }}
            alt="logo"
            className="logo-dark"
          />
          <img
            src={images.LOGO_MAIN}
            style={{ height: 50, width: 90 }}
            alt="logo"
            className="logo-light"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <div
          className="collapse navbar-collapse text-center"
          id="navbarCollapse"
        >
          <ul className="navbar-nav mx-auto navbar-center" id="mySidenav"></ul>
          <ul className="navbar-nav mx-auto navbar-center" id="mySidenav">
            <li className="nav-item">
              <a href="#home" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="#features" className="nav-link">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a href="#testimonial" className="nav-link">
                Testimonial
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact" className="nav-link">
                Contact Us
              </a>
            </li>
          </ul>
          <ul className="navbar-nav navbar-center">
            <li className="nav-item">
              <Link
                target="_blank"
                to={
                  "https://play.google.com/store/apps/details?id=com.sociohood"
                }
                className="btn btn-sm nav-btn"
              >
                Download App
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeaderNew;
