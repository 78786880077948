import React from "react";
import CircularProgressBar from "../../../components/CircularProgressBar";

const Statistics = ({ content }) => {
  return (
    <div className="container">
      <div className="py-5 row w-100 statistics-wrapper">
        <div className="col-lg-6 col-md-12">
          <div className="d-flex flex-column gap-3">
            <p className="fs-2 fw-semibold text-dark">
              {content.statisticsHeader}
            </p>
            <div className="d-flex flex-column gap-3">
              {content.statisticsCards.map((item) => {
                return (
                  <div className="row w-100">
                    <div
                      className="col-lg-2 col-md-2 col-sm-2"
                      style={{ display: "grid", placeItems: "center" }}
                    >
                      <CircularProgressBar progress={item.cardProgress} />
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                      <p className="text-dark">{item.cardDescription}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <span className="d-flex align-items-center justify-content-center px-3">
            <img src={content.statisticsMainImg} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
