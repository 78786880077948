import React from "react";

const HowItWorks = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-8 col-lg-6 text-center">
            <h6 className="subtitle">
              How it <span className="fw-bold">Work</span>
            </h6>
            <h2 className="title">How to get Started ?</h2>
            <p className="text-muted">
              Gain Insights into Our User-Friendly Sociohood.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="work-box px-lg-5 text-center mb-5 mb-lg-0">
              <div className="work-icon bg-soft-primary mb-4">
                <i className="mdi mdi-store-search"></i>
              </div>
              <h5 className="fw-semibold">
                1. Discover
              </h5>
              <p className="text-muted">
                Identify the specific product component that piques your
                interest, whether it's complaint management, visitor management,
                or vendor management.
              </p>
              <img src="images/arrow-top.png" alt="" className="work-arrow" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="work-box px-lg-5 text-center mb-5 mb-lg-0">
              <div className="work-icon bg-soft-success mb-4">
                <i className="mdi mdi-cellphone-text"></i>
              </div>
              <h5 className="fw-semibold">2. Raise a Demo</h5>
              <p className="text-muted">
                Raise a Demo or Contact Request by Filling Out the Form on Our
                Website's Homepage.
              </p>
              <img
                src="images/arrow-bottom.png"
                alt=""
                className="work-arrow"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="work-box px-lg-5 text-center mb-5 mb-lg-0">
              <div className="work-icon bg-soft-warning mb-4">
                <i className="mdi mdi-meditation"></i>
              </div>
              <h5 className="fw-semibold">3. Sit Back and Relax</h5>
              <p className="text-muted">
                We'll Be in Touch with You Within 24 Hours to Provide You with
                the Information and Assistance You Need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
