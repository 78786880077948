import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import escalation from "../../../assets/images/escalation 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const manpowerManagementContent = {
  BannerContent: {
    bannerHeader: "Manpower and Employee Management",
    bannerDescription: `SocioHood's Vendor Management system is an
    integral part of our comprehensive Township
    Management Solution. It streamlines vendor
    interactions, ensures efficient complaint resolution,
    and enhances overall community satisfaction. With
    a dedicated Vendor Portal and robust performance
    monitoring capabilities, it empowers administrators
    and vendors to collaborate seamlessly.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Attendance Tracking:",
      cardDescription:
        "Seamlessly track attendance for employees, staff, and User. Eliminate manual record-keeping and reduce errors.",
    },
    {
      cardSVG: location,
      cardHeader: "Leave Management:",
      cardDescription:
        "Streamline leave requests for employees and User, with automated processing and accurate leave balance calculations.",
    },
    {
      cardSVG: tracking,
      cardHeader: "Shift Scheduling:",
      cardDescription:
        "Ideal for organizations with rotating shifts or communities managing security personnel, the system offers efficient shift assignment and 24/7 coverage.",
    },
    {
      cardSVG: escalation,
      cardHeader: "Biometric Integration (Upon Request):",
      cardDescription:
        "SocioHood integrates with biometric attendance systems on demand, providing hassle-free clock-ins and clock-outs via fingerprint or card-based systems.",
    },
    {
      cardSVG: history,
      cardHeader: "Employee Profiles:",
      cardDescription:
        "Each user has a dedicated profile storing essential details, including contact information, job roles, attendance records, leave history, and more.",
    },
    {
      cardSVG: feedback,
      cardHeader: "Notifications and Alerts:",
      cardDescription:
        "Stay informed with automated notifications about attendance discrepancies, pending leave requests, and other important updates.",
    },
    {
      cardSVG: analytics,
      cardHeader: "Reporting and Analytics:",
      cardDescription:
        "Generate comprehensive reports and analytics on attendance, leaves, and workforce trends, enabling data-driven decisions and resource allocation.",
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Efficiency",
        cardDescription:
          "Automation reduces time spent on attendance and leave tracking for administrators and HR personnel.",
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Accuracy",
        cardDescription:
          "Automated processes minimize errors and disputes, ensuring precise records.",
      },
      {
        cardSVG: energy,
        cardHeader: "Transparency",
        cardDescription:
          "Real-time access to attendance and leave information fosters transparency and trust.",
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Compliance",
        cardDescription:
          "Helps organizations and communities adhere to labor laws and regulations concerning attendance and leaves.",
      },
      {
        cardSVG: review,
        cardHeader: "Resource Optimization",
        cardDescription:
          "Efficient workforce management ensures the right personnel are available when needed, enhancing operational efficiency.",
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Leading Researh Statistics",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 20,
        cardDescription: `McKinsey reports organizations with efficient complaint systems
        experience up to a 20% increase in customer satisfaction.`,
      },
      {
        cardProgress: 15,
        cardDescription: `Zendesk finds that prioritizing quick complaint resolution can reduce
        customer churn by 15%.`,
      },
      {
        cardProgress: 10,
        cardDescription: `Harvard Business Review notes that effective complaint management
        can improve employee morale by 10%.`,
      },
      {
        cardProgress: 30,
        cardDescription: `Gartner highlights that digital complaint solutions can reduce resolution
        times by up to 30%.`,
      },
      {
        cardProgress: 12,
        cardDescription: `The CFI Group's study shows that advanced complaint systems can
        increase customer loyalty by up to 12%.`,
      },
    ],
  },
};
