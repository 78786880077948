import React from "react";
import dots1 from "../../../assets/images/dots 1.png";
import dots2 from "../../../assets/images/dots 2.png";

const TakePreview = ({ content }) => {
  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-lg-4 col-md-12 py-3 px-4 d-flex flex-column justify-content-center preview-left">
          <p className="fs-2 fw-semibold text-dark">
            {content.takePreviewHeader}
          </p>
          <p className="fs-5 text-dark">
            {content.takePreviewDescription}
          </p>
          {/* <span>
            <button className="btn btn-outline-secondary">Watch now</button>
          </span> */}
        </div>
        <div
          className="col-lg-8 col-md-12 d-flex align-items-center justify-content-center preview-right"
          style={{
            padding: "1rem 1.5rem 1rem 6rem",
          }}
        >
          <img className="preview-right-bg-top-right" src={dots1} alt="" />
          <iframe
            className="preview-right-content rounded w-100"
            style={{ height: "340px" }}
            src={content.takePreviewVideo}
            title="main"
            frameborder="0"
            allowfullscreen
          ></iframe>

          <img className="preview-right-bg-bottom-left" src={dots2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TakePreview;
