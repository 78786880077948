import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import escalation from "../../../assets/images/escalation 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const visitorManagementContent = {
  BannerContent: {
    bannerHeader: "Visitor Management",
    bannerDescription: `SocioHood's Visitor Management module is
    designed to enhance security and convenience
    within townships, residential communities, and
    organizations. This comprehensive system
    simplifies the process of visitor registration,
    tracking, and authorization, ensuring a safe and
    seamless experience for User and guests.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Effortless Visitor Registration:",
      cardDescription:
        "User can pre-register their guests through the platform, saving time and enhancing security.",
    },
    {
      cardSVG: location,
      cardHeader: "Real-time Visitor Tracking:",
      cardDescription:
        "Monitor visitor entry and exit in real-time, ensuring that only authorized individuals access the premises.",
    },
    {
      cardSVG: tracking,
      cardHeader: "Instant Notifications:",
      cardDescription:
        "Receive automatic notifications when your guests arrive, offering peace of mind and convenience.",
    },
    {
      cardSVG: escalation,
      cardHeader: "Visitor Verification:",
      cardDescription:
        "Verify guest identities through secure methods like QR codes, enhancing security measures.",
    },
    {
      cardSVG: history,
      cardHeader: "Temporary Access Permissions:",
      cardDescription:
        "Grant temporary access to guests, ensuring they can enter without hassle during their stay.",
    },
    {
      cardSVG: feedback,
      cardHeader: "Historical Visitor Records:",
      cardDescription:
        "Maintain a digital log of all past visitors for reference and security.",
    },
    {
      cardSVG: analytics,
      cardHeader: "Reporting and Analysis:",
      cardDescription:
        "Generate reports on visitor trends and patterns, providing insights into community activity.",
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Enhanced Security",
        cardDescription:
          "Ensure that only authorized visitors gain access, enhancing overall safety.",
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Convenience:",
        cardDescription:
          "Streamline the visitor registration process for both User and their guests.",
      },
      {
        cardSVG: energy,
        cardHeader: "Peace of Mind",
        cardDescription:
          "Instant notifications and real-time tracking offer User peace of mind.",
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Efficiency",
        cardDescription:
          "Save time and reduce administrative burdens with automated visitor management.",
      },
      {
        cardSVG: review,
        cardHeader: "Insights",
        cardDescription:
          "Data-driven reports help administrators make informed decisions about security protocols.",
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Research",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 70,
        cardDescription: `According to the Security Industry
          Association, automated visitor management
          systems can reduce unauthorized entry by up
          to 70%.`,
      },
      {
        cardProgress: 20,
        cardDescription: `A study by the National Apartment
          Association found that communities with
          efficient visitor management systems report
          up to a 25% improvement in resident
          satisfaction.`,
      },
    ],
  },
};
