import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import AllRoutes from "./utils/AllRoutes";
import Home from "./pages/Home/Home";
import Details from "./pages/Details/Details";
import Footer from "./components/Footer";
import BackToTop from "./pages/Home/components/BackToTop";

const App = () => {
  return (
    <BrowserRouter>
      <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="60">
        <Routes>
          <Route path={AllRoutes.HOME} element={<Home />} />
          <Route path="/details/:content" element={<Details />} />
        </Routes>
        <Footer />
        <BackToTop />
      </div>
    </BrowserRouter>
  );
};

export default App;
