import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import escalation from "../../../assets/images/escalation 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const taskManagementContent = {
  BannerContent: {
    bannerHeader: "Task Management System",
    bannerDescription: `SocioHood's Task Management System is a
    comprehensive solution that simplifies task
    assignment, tracking, and completion within
    residential communities, organizations, and
    townships. This feature streamlines workflows,
    fosters accountability, and ensures that tasks are
    efficiently managed.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Task Assignment:",
      cardDescription: `Administrators can easily assign tasks to individuals or teams, specifying deadlines and
      priorities`,
    },
    {
      cardSVG: location,
      cardHeader: "Real-time Tracking:",
      cardDescription: `Users can track the progress of their assigned tasks, view updates, and receive
      notifications for task-related activities.`,
    },
    {
      cardSVG: tracking,
      cardHeader: "Task Priority:",
      cardDescription: `Tasks can be categorized by priority, helping users focus on critical assignments and deadlines.`,
    },
    {
      cardSVG: escalation,
      cardHeader: "Document Management:",
      cardDescription: `Users can attach relevant documents, notes, or instructions to tasks, ensuring clarity and seamless execution`,
    },
    {
      cardSVG: history,
      cardHeader: "Task History:",
      cardDescription: `Maintain a complete history of tasks, including task creation, updates, and closures for
      reference and audit purposes.`,
    },
    {
      cardSVG: feedback,
      cardHeader: "Reminders and Notifications:",
      cardDescription: `Automated reminders and notifications keep users informed about upcoming tasks and
      deadlines.`,
    },
    {
      cardSVG: analytics,
      cardHeader: "Reporting and Analytics:",
      cardDescription: `Generate reports on task completion rates, response times, and performance metrics to
      optimize task management.`,
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Efficiency",
        cardDescription: `Streamlined task assignment and tracking save time and ensure tasks are
        completed on schedule.`,
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Accountability",
        cardDescription: `Assigning tasks and tracking progress fosters accountability among team members
        and residents.`,
      },
      {
        cardSVG: energy,
        cardHeader: "Transparency",
        cardDescription: `Real-time updates and notifications enhance transparency, ensuring everyone
        is on the same page.`,
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Productivity",
        cardDescription: `Prioritizing tasks based on urgency and importance improves productivity and
        time management.`,
      },
      {
        cardSVG: review,
        cardHeader: "Data-Driven Insights",
        cardDescription: `Task-related reports and analytics provide insights for better resource allocation
        and process improvement.`,
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Leading Researh Statistics",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 30,
        cardDescription: `A study by Wrike found that efficient task management systems increase
        team productivity by up to 30%`,
      },
      {
        cardProgress: 20,
        cardDescription: `According to McKinsey, organizations that implement task management
        solutions report a 20% reduction in project completion times.`,
      },
      {
        cardProgress: 15,
        cardDescription: `Harvard Business Review notes that task tracking can lead to a 15%
        improvement in overall task completion rates.`,
      },
      {
        cardProgress: 25,
        cardDescription: `The Project Management Institute (PMI) confirms that automated task
        assignment and tracking reduce the risk of missed deadlines by 25%.`,
      },
      {
        cardProgress: 35,
        cardDescription: `A survey by Trello reveals that teams using task management systems
        see a 35% decrease in time spent on administrative tasks.`,
      },
    ],
  },
};
