import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import login7 from "../../../assets/images/login 7.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const complaintManagementContent = {
  BannerContent: {
    bannerHeader: "Complaint Management System",
    bannerDescription: `SocioHood's Complaint Management system is a versatile and
                      efficient solution that simplifies complaint registration and
                      resolution processes. Designed for use in townships, residential
                      communities, organizations, and more, it ensures complaints are
                      addressed promptly, enhancing satisfaction and harmony.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "User-Friendly Complaint Submission",
      cardDescription:
        "Easily submit complaints with details and images for clarity.",
    },
    {
      cardSVG: location,
      cardHeader: "Automated Routing",
      cardDescription:
        "Complaints are automatically directed to the right department or personnel for swift resolution.",
    },
    {
      cardSVG: tracking,
      cardHeader: "Progress Tracking",
      cardDescription:
        "Monitor complaint progress in real-time, receiving updates at each resolution stage.",
    },
    {
      cardSVG: history,
      cardHeader: "Historical Data",
      cardDescription:
        "Maintain a comprehensive database for trend analysis and process improvements.",
    },
    {
      cardSVG: feedback,
      cardHeader: "Feedback Loop",
      cardDescription:
        "User can provide feedback, fostering continuous service improvement.",
    },
    {
      cardSVG: analytics,
      cardHeader: "Reporting and Analysis",
      cardDescription:
        "Generate reports on complaint trends, response times, and resolution rates for informed decisions.",
    },
    {
      cardSVG: login7,
      cardHeader: "Contact Admin and Vendor",
      cardDescription:
        "User have the convenience of directly contacting administrators and vendors related to the specific complaint they've raised.",
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Quick Resolution:",
        cardDescription:
          "Automated routing and tracking ensure prompt complaint handling.",
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Transparency:",
        cardDescription:
          "Real-time updates and a feedback loop build trust among users.",
      },
      {
        cardSVG: energy,
        cardHeader: "Efficiency:",
        cardDescription:
          "Streamlined processes save time and resources for administrators.",
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Data-Driven Improvements:",
        cardDescription:
          "Reporting enables data-driven decisions and process enhancements.",
      },
      {
        cardSVG: review,
        cardHeader: "Enhanced Satisfaction:",
        cardDescription:
          "Swift complaint resolution boosts resident satisfaction in diverse settings.",
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Leading Research Statistics:",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 20,
        cardDescription:
          "McKinsey reports organizations with efficient complaint systems experience up to a 20% increase in customer satisfaction.",
      },
      {
        cardProgress: 15,
        cardDescription:
          "Zendesk finds that prioritizing quick complaint resolution can reduce customer churn by 15%.",
      },
      {
        cardProgress: 10,
        cardDescription:
          "Harvard Business Review notes that effective complaint management can improve employee morale by 10%.",
      },
      {
        cardProgress: 12,
        cardDescription:
          "The CFI Group's study shows that advanced complaint systems can increase customer loyalty by up to 12%.",
      },
      {
        cardProgress: 30,
        cardDescription:
          "Gartner highlights that digital complaint solutions can reduce resolution times by up to 30%.",
      },
    ],
  },
};
