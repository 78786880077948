import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import escalation from "../../../assets/images/escalation 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import analytics from "../../../assets/images/analytics 1.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const communityManagementContent = {
  BannerContent: {
    bannerHeader: "Community Management",
    bannerDescription: `SocioHood's Community Management module
      is a versatile solution tailored to foster better
      communication, engagement, and efficiency
      within residential communities, townships, and
      organizations. This dynamic platform brings
      User together, enhancing their living
      experience.`,
    bannerImg: Mockup,
  },
  
  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Announcements and Updates:",
      cardDescription:
        "Administrators can post announcements, news, and updates for all community members to see.",
    },
    {
      cardSVG: location,
      cardHeader: "Community Forums:",
      cardDescription:
        "Foster community interaction and discussions through dedicated forums and groups.",
    },
    {
      cardSVG: tracking,
      cardHeader: "Event Management:",
      cardDescription:
        "Organize and manage community events, gatherings, and activities through a centralized calendar.",
    },
    {
      cardSVG: escalation,
      cardHeader: "Resident Directories:",
      cardDescription:
        "Access a comprehensive directory of User, making it easy to connect with neighbors.",
    },
    {
      cardSVG: history,
      cardHeader: "Document Sharing:",
      cardDescription:
        "Share important documents, such as community guidelines and newsletters, with ease.",
    },
    {
      cardSVG: feedback,
      cardHeader: "Feedback Mechanisms:",
      cardDescription:
        "Allow User to provide feedback and suggestions for continuous improvement.",
    },
    {
      cardSVG: analytics,
      cardHeader: "Mobile Accessibility:",
      cardDescription:
        "User can engage with the community on the go through the mobile app.",
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Community Building:",
        cardDescription:
          "Strengthen the sense of community and belonging among User.",
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Efficient Communication",
        cardDescription:
          "Streamline communication between administrators and User.",
      },
      {
        cardSVG: energy,
        cardHeader: "Increased Engagement",
        cardDescription:
          "Encourage User to participate in community events and discussions.",
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Information Accessibility",
        cardDescription:
          "User can easily access important documents and directories.",
      },
      {
        cardSVG: review,
        cardHeader: "Transparency",
        cardDescription:
          "Open channels for feedback and ensure User are heard.",
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Research",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 30,
        cardDescription: `A study by Community Associations Institute
          reports that efficient community management
          platforms can increase resident satisfaction by
          up to 30%.`,
      },
      {
        cardProgress: 20,
        cardDescription: `The Urban Land Institute finds that
          communities with active online engagement
          experience up to a 20% boost in resident
          participation in community events.`,
      },
    ],
  },
};
