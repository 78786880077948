import React from "react";
import { useParams } from "react-router";
import ScrollToTopOnRouteChange from "../../components/ScrollToTopOnRouteChange";
import DetailsHeader from "../../components/DetailsHeader";
import DetailsBody from "./components/DetailsBody";
import { complaintManagementContent } from "./contents/complaintManagementContent";
import { communityManagementContent } from "./contents/communityManagementContent";
import { visitorManagementContent } from "./contents/visitorManagementContent";
import { vendorManagementContent } from "./contents/vendorManagementContent";
import { manpowerManagementContent } from "./contents/manpowerManagementContent";
import { securityManagementContent } from "./contents/securityManagementContent";
import { taskManagementContent } from "./contents/taskManagementContent";
import { assetManagementContent } from "./contents/assetManagementContent";

const Details = () => {
  const { content } = useParams();

  const getContent = () => {
    switch (content) {
      case "complaint":
        return complaintManagementContent;
      case "vendor":
        return vendorManagementContent;
      case "visitor":
        return visitorManagementContent;
      case "community":
        return communityManagementContent;
      case "manpower":
        return manpowerManagementContent;
      case "security":
        return securityManagementContent;
      case "task":
        return taskManagementContent;
      case "asset":
        return assetManagementContent;
      default:
        return complaintManagementContent;
    }
  };

  return (
    <>
      <ScrollToTopOnRouteChange />
      <DetailsHeader />
      <DetailsBody content={getContent()} />
    </>
  );
};

export default Details;
