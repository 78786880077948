import React from "react";
import { images } from "../../../utils/images";
import { Link } from "react-router-dom";

const Features = () => {
  return (
    <>
      <section className="section bg-light" id="features">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-8 col-lg-6 text-center">
              <h6 className="subtitle">
                Our <span className="fw-bold">Features</span>
              </h6>
              <h2 className="title">Discover Our Comprehensive Features</h2>
              <p className="text-muted">
                Efficiently Manage Your society with Our Powerful Tools
              </p>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={images.COMPLAINT_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h1 className="fs-38 mb-4">Complaint Management</h1>
              <p className="text-muted">
                SocioHood's Complaint Management system is a versatile and
                efficient solution that simplifies complaint registration and
                resolution processes. Designed for use in townships, residential
                communities, organizations, and more, it ensures complaints are
                addressed promptly, enhancing satisfaction and harmony.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    <span className="text-dark fw-bold"></span> Easily submit
                    complaints with details and images for clarity.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Complaints are automatically directed to the right
                    department or personnel for swift resolution.
                  </p>
                </div>
              </div>

              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Monitor complaint progress in real-time, receiving updates
                    at each resolution stage.
                  </p>
                </div>
              </div>
              <Link
                to="/details/complaint"
                className="btn bg-light border custom-btn"
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light features-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h1 className="fs-38 mb-4">Vendor Management</h1>
              <p className="text-muted">
                SocioHood's Vendor Management system is an integral part of our
                comprehensive Township Management Solution. It empowers
                administrators and vendors to collaborate seamlessly.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    The Vendor Portal provides vendors with direct
                    <span className="text-dark fw-bold">
                      {" "}
                      access to the system
                    </span>{" "}
                    , allowing them to view assigned tasks, respond to
                    complaints, and update job statuses in real-time. This
                    transparency fosters clear communication between vendors and
                    administrators.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    SocioHood's system tracks vendor performance metrics,
                    including response times, issue resolution rates, and
                    resident feedback. This data is crucial for assessing vendor
                    effectiveness and making informed decisions.
                  </p>
                </div>
              </div>

              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Vendors can generate invoices directly through the system,
                    streamlining the billing process and reducing paperwork.
                  </p>
                </div>
              </div>
              <span className="d-flex justify-content-end">
                <Link
                  to="/details/vendor"
                  className="btn bg-light border custom-btn"
                >
                  View Details
                </Link>
              </span>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <img
                src={images.VENDOR_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={images.VISITOR_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h1 className="fs-38 mb-4">Visitor Management</h1>
              <p className="text-muted">
                SocioHood's Visitor Management module is designed to enhance
                security and convenience within townships, residential
                communities, and organizations. This comprehensive system
                simplifies the process of visitor registration, tracking, and
                authorization, ensuring a safe and seamless experience for User
                and guests.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    User can pre-register their guests through the platform,
                    saving time and enhancing security.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Monitor visitor entry and exit in real-time, ensuring that
                    only authorized individuals access the premises.
                  </p>
                </div>
              </div>

              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Verify guest identities through secure methods like QR
                    codes, enhancing security measures.
                  </p>
                </div>
              </div>
              <Link
                to="/details/visitor"
                className="btn bg-light border custom-btn"
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light features-bg">
        <div className="container" style={{ paddingBottom: 90 }}>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h1 className="fs-38 mb-4">Community Management</h1>
              <p className="text-muted">
                SocioHood's Community Management module is a versatile solution
                tailored to foster better communication, engagement, and
                efficiency within residential communities, townships, and
                organizations.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Administrators can post announcements, news, and updates for
                    all community members to see.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Foster community interaction and discussions through
                    dedicated forums and groups.
                  </p>
                </div>
              </div>

              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Organize and manage community events, gatherings, and
                    activities through a centralized calendar.
                  </p>
                </div>
              </div>
              <span className="d-flex justify-content-end">
                <Link
                  to="/details/community"
                  className="btn bg-light border custom-btn"
                >
                  View Details
                </Link>
              </span>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <img
                src={images.COMMUNITY_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-light" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={images.VISITOR_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h1 className="fs-38 mb-4">Manpower and Employee Management</h1>
              <p className="text-muted">
                SocioHood's Visitor Management module is designed to enhance
                security and convenience within townships, residential
                communities, and organizations. This comprehensive system
                simplifies the process of visitor registration, tracking, and
                authorization, ensuring a safe and seamless experience for User
                and guests.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    User can pre-register their guests through the platform,
                    saving time and enhancing security.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Monitor visitor entry and exit in real-time, ensuring that
                    only authorized individuals access the premises.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Verify guest identities through secure methods like QR
                    codes, enhancing security measures.
                  </p>
                </div>
              </div>
              <Link
                to="/details/manpower"
                className="btn bg-light border custom-btn"
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light features-bg">
        <div className="container" style={{ paddingBottom: 90 }}>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h1 className="fs-38 mb-4">
                Security and Alert Management System
              </h1>
              <p className="text-muted">
                SocioHood's Community Management module is a versatile solution
                tailored to foster better communication, engagement, and
                efficiency within residential communities, townships, and
                organizations.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Administrators can post announcements, news, and updates for
                    all community members to see.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Foster community interaction and discussions through
                    dedicated forums and groups.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Organize and manage community events, gatherings, and
                    activities through a centralized calendar.
                  </p>
                </div>
              </div>
              <span className="d-flex justify-content-end">
                <Link
                  to="/details/security"
                  className="btn bg-light border custom-btn"
                >
                  View Details
                </Link>
              </span>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <img
                src={images.COMMUNITY_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={images.VISITOR_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h1 className="fs-38 mb-4">Task Management System</h1>
              <p className="text-muted">
                SocioHood's Visitor Management module is designed to enhance
                security and convenience within townships, residential
                communities, and organizations. This comprehensive system
                simplifies the process of visitor registration, tracking, and
                authorization, ensuring a safe and seamless experience for User
                and guests.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    User can pre-register their guests through the platform,
                    saving time and enhancing security.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Monitor visitor entry and exit in real-time, ensuring that
                    only authorized individuals access the premises.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Verify guest identities through secure methods like QR
                    codes, enhancing security measures.
                  </p>
                </div>
              </div>
              <Link
                to="/details/task"
                className="btn bg-light border custom-btn"
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light features-bg">
        <div className="container" style={{ paddingBottom: 90 }}>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h1 className="fs-38 mb-4">Asset Booking and Management</h1>
              <p className="text-muted">
                SocioHood's Community Management module is a versatile solution
                tailored to foster better communication, engagement, and
                efficiency within residential communities, townships, and
                organizations.
              </p>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-check"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Administrators can post announcements, news, and updates for
                    all community members to see.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-layers-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Foster community interaction and discussions through
                    dedicated forums and groups.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-shrink-0">
                  <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                    <i className="mdi mdi-lock-outline"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-4">
                  <p className="text-muted">
                    Organize and manage community events, gatherings, and
                    activities through a centralized calendar.
                  </p>
                </div>
              </div>
              <span className="d-flex justify-content-end">
                <Link
                  to="/details/asset"
                  className="btn bg-light border custom-btn"
                >
                  View Details
                </Link>
              </span>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <img
                src={images.COMMUNITY_MANAGEMENT}
                alt=""
                className="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
