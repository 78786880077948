import React from "react";

const Testimonials = () => {
  return (
    <section className="section bg-light" id="testimonial">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-8 col-lg-6 text-center">
            <h6 className="subtitle">
              Our <span className="fw-bold">Testimonials</span>
            </h6>
            <h2 className="title">What Our Customers Say</h2>
            <p className="text-muted">
              Resident Stories: Hear What Our Community Members Have to Say
            </p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4">
            <div className="testimonial-box text-center">
              <h5 className="fs-18 fw-semibold lh-base mb-4 pb-3">
                “Sociohood’s Township Management System would be very useful for
                the township. It would surely increase the efficiency level. We
                have seen the effort sociohood team has put behind this
                project.”
              </h5>
              <img
                src={
                  "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_1280.png"
                }
                alt="user 1"
                className="shadow rounded-circle"
                width="70"
                height="70"
              />
              <h5 className="fs-18 fw-semibold mt-4 mb-0">Ramphal Nehra</h5>
              <p className="text-muted fs-14">Tata Cummins, Plant Head</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="testimonial-box text-center">
              <h5 className="fs-18 fw-semibold lh-base mb-4 pb-3">
                “I can say it’s a very memorable event, launching of sociohood
                township management system. We did start industry 4.0 in our
                plant and same digitalization journey has been extended to the
                township. I am confident this would be very useful.”
              </h5>
              <img
                src={
                  "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_1280.png"
                }
                alt="user 2"
                className="shadow rounded-circle"
                width="70"
                height="70"
              />
              <h5 className="fs-18 fw-semibold mt-4 mb-0">Ravindra Kulkarni</h5>
              <p className="text-muted fs-14">Tata Motors, Plant Head</p>
            </div>
          </div>
        </div>

        {/* <div className="row mt-md-5">
          <div className="col-sm-6 col-md-3">
            <div className="text-center py-3">
              <img src="images/brand-logo/dribbble.png" alt="" height="40" />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="text-center py-3">
              <img src="images/brand-logo/insta.png" alt="" height="40" />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="text-center py-3">
              <img src="images/brand-logo/bootstrap.png" alt="" height="40" />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="text-center py-3">
              <img src="images/brand-logo/jquery.png" alt="" height="40" />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Testimonials;
