import React from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../utils/AllRoutes";
import { images } from "../utils/images";

const Footer = () => {
  return (
    <>
      <section className="footer bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-center">
              <div className="footer-logo mb-4">
                <Link to={AllRoutes.HOME}>
                  <img
                    style={{ height: 50, width: 90 }}
                    src={images.LOGO_MAIN}
                    alt=""
                  />
                </Link>
              </div>
              <Link to={"mailto:contact@sociohood.com"} className="text-white">
                contact@sociohood.com
              </Link>
              <p className="text-white">(+91) 84098 97416</p>
              <p className="text-white">(+91) 79802 44189</p>

              <h5 className="fs-18 mb-3 text-white">Follow Us</h5>
              <ul className="list-inline mt-5">
                <li className="list-inline-item">
                  <Link to={null} className="footer-social-icon">
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={null} className="footer-social-icon">
                    <i className="mdi mdi-twitter"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={null} className="footer-social-icon">
                    <i className="mdi mdi-linkedin"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={null} className="footer-social-icon">
                    <i className="mdi mdi-skype"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-12 col-md-6 text-center">
                  <h5 className="fs-22 mb-3 fw-semibold text-white">
                    About Us
                  </h5>
                  <ul className="list-unstyled footer-nav">
                    <li>
                      <Link to={AllRoutes.HOME} className="footer-link">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to={null} className="footer-link">
                        Customer Support
                      </Link>
                    </li>
                    <li>
                      <Link to={null} className="footer-link">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to={null} className="footer-link">
                        Copyright
                      </Link>
                    </li>
                    <li>
                      <Link to={null} className="footer-link">
                        Popular Campaign
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-tagline">
        <div className="container">
          <div className="row justify-content-between py-2">
            <div className="col-md-6">
              <p className="text-white opacity-75 mb-0 fs-14">
                2023 © Sociohood -{" "}
                <Link to="https://sociohood.com/" className="text-white">
                  Sociohood.com
                </Link>
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <Link
                target="_blank"
                to={
                  "https://sites.google.com/view/sociohood-technologies-tnc/home"
                }
                className="text-white opacity-75 fs-14"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
