import Mockup from "../../../assets/images/Laptop Mockup Instagram Post 1.png";
import login1 from "../../../assets/images/login 1.svg";
import location from "../../../assets/images/location-pin 1.svg";
import tracking from "../../../assets/images/time-tracking 1.svg";
import escalation from "../../../assets/images/escalation 1.svg";
import history from "../../../assets/images/history 1.svg";
import feedback from "../../../assets/images/feedback 1.svg";
import phone from "../../../assets/images/Business Professional Phone Mockup Instagram Post 1.svg";
import lightbulb from "../../../assets/images/lightbulb 1.svg";
import searchEngine from "../../../assets/images/search-engine.svg";
import energy from "../../../assets/images/energy.svg";
import dataDriven from "../../../assets/images/data-driven 1.svg";
import review from "../../../assets/images/review.svg";

export const securityManagementContent = {
  BannerContent: {
    bannerHeader: "Security and Alert Management System",
    bannerDescription: `SocioHood's Security and Alert Management System is
    designed to prioritize and manage urgent notifications
    and safety alerts within residential communities,
    organizations, and townships. This feature ensures that
    critical messages receive immediate attention, enhancing
    the overall safety and security of the community.`,
    bannerImg: Mockup,
  },

  keyFeaturesContent: [
    {
      cardSVG: login1,
      cardHeader: "Priority Alerts",
      cardDescription: `Administrators can create priority alerts
        that stand out from regular notifications,
        ensuring that important messages are
        noticed promptly.`,
    },
    {
      cardSVG: location,
      cardHeader: "SOS Alerts",
      cardDescription: `Users have the ability to raise SOS alerts in case of
      emergencies, such as medical issues, security concerns,
      or accidents. These alerts are immediately
      communicated to the relevant departments or
      authorities.`,
    },
    {
      cardSVG: tracking,
      cardHeader: "Customizable Notifications",
      cardDescription: `Users can personalize their alert
      preferences, choosing how they want to be
      notified during urgent situations, such as
      through push notifications, SMS, or audible
      alarms.
      `,
    },
    {
      cardSVG: escalation,
      cardHeader: "Two-Way Communication",
      cardDescription: `The system facilitates two-way
      communication between users and
      administrators, allowing for real-time
      updates, clarifications, and responses
      during critical events.`,
    },
    {
      cardSVG: history,
      cardHeader: "Location-Based Alerts",
      cardDescription: `When users raise alerts, their location is
      automatically shared with responders,
      enabling faster and more accurate
      assistance.`,
    },
    {
      cardSVG: feedback,
      cardHeader: "Alert History",
      cardDescription: `Administrators can access the alert history to review
      past incidents, response times, and user feedback,
      enabling continuous improvement in emergency
      management.`,
    },
  ],

  takePreviewContent: {
    takePreviewHeader: "Take Preview",
    takePreviewDescription:
      "Watch this 90 seconds clip that will help you understand our product.",
    takePreviewVideo: "https://www.youtube.com/embed/NQTbOV4OocE",
  },

  benefitsContent: {
    benefitsHeader: "Benefits",
    benefitsMainImg: phone,
    benefitsCards: [
      {
        cardSVG: lightbulb,
        cardHeader: "Enhanced Safety",
        cardDescription: `Priority and SOS alerts ensure that residents and employees receive timely
        assistance during emergencies.`,
      },
      {
        cardSVG: searchEngine,
        cardHeader: "Rapid Response",
        cardDescription: `Two-way communication and location-based alerts help responders
        react swiftly and effectively.`,
      },
      {
        cardSVG: energy,
        cardHeader: "User Empowerment",
        cardDescription: `Users have the ability to request help or report issues with ease,
        promoting a sense of security.`,
      },
      {
        cardSVG: dataDriven,
        cardHeader: "Customization",
        cardDescription: `Customizable notification preferences allow users to receive alerts in their
        preferred format.`,
      },
      {
        cardSVG: review,
        cardHeader: "Incident Analysis",
        cardDescription: `Access to alert history enables administrators to assess response times and
        make data-driven improvements.`,
      },
    ],
  },

  statisticsContent: {
    statisticsHeader: "Research",
    statisticsMainImg: phone,
    statisticsCards: [
      {
        cardProgress: 65,
        cardDescription: `A study by the National Emergency Number Association (NENA) found
        that location-based alerts reduce emergency response times by up to 65%.`,
      },
      {
        cardProgress: 30,
        cardDescription: `According to FEMA (Federal Emergency Management Agency),
        communities with effective alert management systems report a 30%
        increase in resident safety awareness.`,
      },
      {
        cardProgress: 50,
        cardDescription: `The American Red Cross notes that timely SOS alerts can reduce the
        severity of medical emergencies by up to 50%.`,
      },
      {
        cardProgress: 80,
        cardDescription: `A survey by Rave Mobile Safety shows that 80% of users find
        customizable alert notifications more useful during emergencies.`,
      },
      {
        cardProgress: 40,
        cardDescription: `The Department of Homeland Security (DHS) confirms that two-way
        communication in alert systems improves emergency response accuracy
        by 40%.`,
      },
    ],
  },
};
