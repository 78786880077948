import React from "react";
import google from "../../../assets/images/app-play-google-android-store.png";
// import AppStore from "../../../assets/images/Download_on_the_App_Store_logo.png";
import bottom from "../../../assets/images/hero-1-bottom-shape.png";

const DetailsBanner = ({ content }) => {
  return (
    <>
      <section className="hero-1">
        <div className="bg-overlay-img"></div>
        <div className="container">
          <div className="row align-items-center hero-content">
            <div className="col-lg-6">
              <h1 className="hero-title fw-bold mb-4 pb-3 text-white">
                {content.bannerHeader}
              </h1>
              <p className="text-white opacity-75 mb-4 pb-3 fs-15">
                {content.bannerDescription}
              </p>
              <div className="d-flex align-items-center gap-3">
                {/* <img src={AppStore} alt="" className="img-fluid h-100" /> */}
                <img src={google} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-end">
              <img src={content.bannerImg} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="hero-bottom-shape">
                <img src={bottom} alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsBanner;
